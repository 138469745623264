import {captureException} from "@sentry/browser";
import Alpine from "alpinejs";

Alpine.data("formImage", (init = {}) => ({
    changed: false,
    defaultValue: init.defaultValue ?? null,
    value: init.value ?? null,
    reader: new FileReader(),

    init() {
        if (this.value === this.defaultValue) this.value = null;
        this.reader.onload = ({target}) => {
            this.changed = true;
            this.value = target.result;
        };
    },

    fileChanged({target}) {
        this.read(target.files[0]);
    },

    fileDropped({dataTransfer}) {
        this.read(dataTransfer.files[0]);
    },

    read(blob) {
        if (blob instanceof Blob) this.reader.readAsDataURL(blob);
        else if(!blob) {
            this.changed = true;
            this.value = null;
        }
        else captureException(new Error(`File blob wasn't a Blob but ${blob?.constructor?.name ?? typeof blob ?? "unknown"}`));
    },

    reset() {
        this.changed = true;
        this.value = null;
    },

    showModal() {
        this.$refs.file.click();
    },
}));
