import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";

import "./form/image";
import "./locationPrompt";

window.Alpine = Alpine;

Alpine.plugin(collapse);

Alpine.start();
